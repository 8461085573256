import React from "react"
import "../global.css"
import { graphql, useStaticQuery } from "gatsby"
import HeadMetadata from "../components/HeadMetadata"
import Layout from "../layout/Layout"
import CallToAction from '../layout/CallToActionC'
import Img from "gatsby-image"

export default () => {
  const img = useStaticQuery(graphql`
    {
      fondoHero: file(relativePath: { eq: "santa1.jpg" }) {
        sharp: childImageSharp { fluid(maxWidth: 1800, quality:90) { ...GatsbyImageSharpFluid } } }
      img2: file(relativePath: { eq: "santa2.jpg" }) { 
        sharp: childImageSharp { fluid(maxWidth: 1800,quality:90) { ...GatsbyImageSharpFluid } } }
      img3: file(relativePath: { eq: "santa3.jpg" }) {
        sharp: childImageSharp { fluid(maxWidth:1800, quality:90) { ...GatsbyImageSharpFluid } } }
      img4: file(relativePath: { eq: "santa4.jpg" }) {
        sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
      img5: file(relativePath: { eq: "santa5.jpg" }) {
        sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
      img6: file(relativePath: { eq: "santa6.jpg" }) {
        sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
      img7: file(relativePath: { eq: "santa7.jpg" }) {
            sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
      img8: file(relativePath: { eq: "santa8.jpg" }) {
            sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
      img9: file(relativePath: { eq: "santa9.jpg" }) {
            sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
      img10: file(relativePath: { eq: "santa10.jpg" }) {
            sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
      img11: file(relativePath: { eq: "santa11.jpg" }) {
            sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
      img12: file(relativePath: { eq: "santa12.jpg" }) {
            sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
      img13: file(relativePath: { eq: "santa13.jpg" }) {
            sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
        
        
        img1Res: file(relativePath: { eq: "santaVer1.jpg" }) { 
          sharp: childImageSharp { fluid(maxWidth: 1800,quality:90) { ...GatsbyImageSharpFluid } } }
        img2Res: file(relativePath: { eq: "santaVer2.jpg" }) {
          sharp: childImageSharp { fluid(maxWidth:1800, quality:90) { ...GatsbyImageSharpFluid } } }
        img3Res: file(relativePath: { eq: "santaVer3.jpg" }) {
          sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
        img4Res: file(relativePath: { eq: "santaVer4.jpg" }) {
          sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
        img5Res: file(relativePath: { eq: "santaVer5.jpg" }) {
          sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
        img6Res: file(relativePath: { eq: "santaVer6.jpg" }) {
            sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
        img7Res: file(relativePath: { eq: "santaVer7.jpg" }) {
            sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
        img8Res: file(relativePath: { eq: "santaVer8.jpg" }) {
            sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
        img9Res: file(relativePath: { eq: "santaVer9.jpg" }) {
            sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
        img10Res: file(relativePath: { eq: "santaVer10.jpg" }) {
            sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
        img11Res: file(relativePath: { eq: "santaVer11.jpg" }) {
            sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
        img12Res: file(relativePath: { eq: "santaVer12.jpg" }) {
            sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
        img13Res: file(relativePath: { eq: "santaVer13.jpg" }) {
            sharp: childImageSharp { fluid(maxWidth: 1920, quality:90) { ...GatsbyImageSharpFluid } } }
       
          
    }
  `)

  return (
    <Layout className="overflow-hidden">
      <HeadMetadata title="Desarrollo Santa Mar - Grupo Libera" />      
      
      <section>
          <Img fluid={img.fondoHero.sharp.fluid}  className="hidden lg:block" /> 
          <Img fluid={img.img1Res.sharp.fluid}  className="lg:hidden" />
      </section>
      <section>
          <Img fluid={img.img2.sharp.fluid}  className="hidden lg:block" /> 
          <Img fluid={img.img2Res.sharp.fluid}  className="lg:hidden" />
      </section>
      <section>
          <Img fluid={img.img3.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.img3Res.sharp.fluid}  className="lg:hidden" />
      </section>
      <section>
          <Img fluid={img.img4.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.img4Res.sharp.fluid}  className="lg:hidden" />
      </section>
      <section>
          <Img fluid={img.img5.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.img5Res.sharp.fluid}  className="lg:hidden" />
      </section>
      <section>
          <Img fluid={img.img6.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.img6Res.sharp.fluid}  className="lg:hidden" />
      </section>
      <section>
          <Img fluid={img.img7.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.img7Res.sharp.fluid}  className="lg:hidden" />
      </section>

      <section>
          <Img fluid={img.img8.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.img8Res.sharp.fluid}  className="lg:hidden" />
      </section>
      
      <section>
          <Img fluid={img.img9.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.img9Res.sharp.fluid}  className="lg:hidden" />
      </section>

      <section>
          <Img fluid={img.img10.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.img10Res.sharp.fluid}  className="lg:hidden" />
      </section>
      <a href="https://grupolibera.mx/pdf/catalogoSantamar.pdf" target="_blank">
        <section>
            <Img fluid={img.img11.sharp.fluid}  className="hidden lg:block" />
            <Img fluid={img.img11Res.sharp.fluid}  className="lg:hidden" />
        </section>
      </a>
      
      <section>
          <Img fluid={img.img12.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.img12Res.sharp.fluid}  className="lg:hidden" />
      </section>
      <a href="https://grupolibera.mx/desarrolloSantaMar/disponibilidad" target="_blank">
      <section>
          <Img fluid={img.img13.sharp.fluid}  className="hidden lg:block" />
          <Img fluid={img.img13Res.sharp.fluid}  className="lg:hidden" />
      </section>
      </a>

      <CallToAction
        phone="tel:9993462300"
        whatsapp="https://wa.me/+529993462300"
        email="mailto:info@grupolibera.mx"
      />

    </Layout>
  )
}
